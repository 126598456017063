<template>
  <div>
    <div class="container" id="invoice">
      <div class="row mt-5">
        <div class="col-6">
          <p style="text-align: initial;">{{$t('account_statement_print.account_statement_for')}}:</p>
          <p style="text-align: initial;">{{data ? data.name : ''}}</p>
        </div>
        <div class="col-6">
          <h4 style="text-align: end;">{{company.business_name}}</h4>
          <p style="text-align: end;">{{company.address_1}}</p>
          <p style="text-align: end;">{{company.city ? company.city.name : ''}}, {{company.country ? company.country.name : ''}}</p>
        </div>
      </div>
      <div class="row mt-5" v-for="(row, index) in data.basic_data" :key="'balances'+index">
        <table class="table table-bordered">
          <thead>
          <tr style="background-color: #f8f9fa;">
            <th colspan="2">
              {{$t('account_statement_print.account_summary')}}
              <span v-if="account_statement.from_date"> {{ $t('account_statement_print.from') }} {{account_statement.from_date}} </span>
              <span v-if="account_statement.to_date"> {{ $t('account_statement_print.to') }} {{account_statement.to_date}} </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th width="70%">{{$t('account_statement_print.opening_balance')}}</th>
            <td width="30%">{{ row.opening_balance }} {{ row.currency }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.total')}}</th>
            <td width="30%">{{ row.total }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.paid_to_date')}}</th>
            <td width="30%">{{ row.paid_to_date }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.deserved_amount')}}</th>
            <td width="30%">{{ row.deserved_amount }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-5">
        <h4>{{$t('account_statement_print.account_transactions')}}</h4>
        <table class="table table-bordered">
          <thead>
          <tr style="background-color: #f8f9fa;">
            <th>{{$t('account_statement_print.the_date')}}</th>
            <th>{{$t('account_statement_print.operation_type')}}</th>
            <th>{{$t('account_statement_print.the_amount')}}</th>
            <th>{{$t('account_statement_print.the_currency')}}</th>
            <th>{{$t('account_statement_print.exchange_rate')}}</th>
            <th>{{$t('account_statement_print.balance_before')}}</th>
            <th>{{$t('account_statement_print.balance_after')}}</th>
            <th>{{$t('account_statement_print.amount_due_before')}}</th>
            <th>{{$t('account_statement_print.the_amount_due_after')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr  v-for="(row, index) in data.movements" :key="'movement'+index">
            <td>{{row.date}}</td>
            <td>{{row.type}}</td>
            <td>{{row.amount}}</td>
            <td>{{row.currency}}</td>
            <td>{{row.exchange_rate}}</td>
            <td>{{row.balance_before}}</td>
            <td>{{row.balance_after}}</td>
            <td>{{row.debit_before}}</td>
            <td>{{row.debit_after}}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="8">{{$t('account_statement_print.deserved_amount')}}</th>
            <td>{{data ? data.total_debit : '0'}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "accountStatement-Supplier",
  data(){
    return {
      mainRoute:'purchases/supplier/supplier_statement',
      mainRouteForCompany:'settings/company/getCompany',
      data:{},
      company:{},
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      account_statement:{
        from_date: null,
        to_date: null,
      },

    }
  },
  methods: {
    ...timeZoneStructure,
    async getData() {
      await ApiService.query(`${this.mainRoute}/${this.id}`,this.account_statement).then((response) => {
        this.data = response.data.data;
      });
    },
    async getCompany() {
      await ApiService.get(this.mainRouteForCompany).then((response) => {
        this.company = response.data.data ? response.data.data :  {};
      });
    },
    printInvoice() {
      let tableId = 'invoice'
      let divToPrint = document.getElementById(tableId);
      window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" +  divToPrint.outerHTML);
      i18nService.getActiveLanguage();
      setTimeout(() => {
        window.print();
        window.close();
      }, 100)
    }
  },
  created() {
    this.account_statement.from_date = this.$route.query.from_date ? this.$route.query.from_date :  null;

    this.account_statement.to_date = this.$route.query.to_date;

    let promise = this.getData();
    let promise2 = this.getCompany();
    let promise3 = null;

    if (!this.account_statement.to_date){
      promise3 = this.getTimeZone().then((res)=>{
        this.account_statement.to_date = res;
      });
    }

    Promise.all([promise, promise2]).then(()=>{
      this.show = true;
      this.printInvoice();
    });
  },
}
</script>

<style scoped>

</style>